import React from 'react';
import '../index.css';

export default function Footer() {
    return (
        <div class="footer">
            <a href="https://www.linkedin.com/in/amelie-engelmaierov%C3%A1-a94b4a136/">LinkedIn</a>
            <a href="mailto:ikoronka.work@gmail.com">mail</a>
            <a href="https://github.com/ikoronka/">github</a>
        </div>
    );
}
